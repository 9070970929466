
const envs = {
    PROD: {
        value: 'PROD'
    },
    DEV: {
        value: 'DEV'
    }
};

export default envs;
